import { ButtonProps, Button as ChakraButton } from '@chakra-ui/react'

interface iButtonProps extends ButtonProps{
  VarColor?: string
  OutlineColor?: string
  Hover?: any
  small?: boolean
}
export const ButtonCmp: React.FC<iButtonProps> = ({ small, OutlineColor, fontSize, size, VarColor, Hover, children, px, h, w, ...rest }) => {
  return (
        <ChakraButton
            {...rest}
            borderRadius={'.25rem'}
            filter='auto'
            size={size}
            maxW={w}
            minW={w}
            h={small ? '1.75rem' : h ?? '1.925rem'}
            fontSize={small ? '.75rem' : fontSize ?? '14px'}
            py={'.125rem'}
            px={px ?? '.75rem'}
            color={OutlineColor ? `var(--${OutlineColor})` : 'var(--c1)'}
            border={OutlineColor ? `2px solid var(--${OutlineColor})` : ''}
            _hover={Hover ?? {
              bg: (VarColor && `var(--${VarColor}-hover)`) ?? (OutlineColor && `var(--${OutlineColor})`),
              boxShadow: 'var(--SombraBtns)',
              color: 'var(--c1)',
              transition: 'var(--transitionBtns);'
            }}
            bg={OutlineColor ? 'white' : VarColor && `var(--${VarColor})`
        }>{children}</ChakraButton>
  )
}
