import { Flex, useToast } from '@chakra-ui/react'
import { HeaderSection, ModalBody, SectionAssociados, SectionBody } from './styles'
import { appApi } from '../../../../../../services/appApi'
import { ModalBase } from '../../../../../../components/ModalBase'
import { ButtonCmp } from '../../../../../../components/ButtonCmp'
import { IEmployee, iPostAvaliador } from '../../../../../../interfaces'
import { useEffect, useState } from 'react'
import { ModalAddParticipante } from '../ModalAddParticipante'
import { CardAssociado } from '../CardAssociado'

interface iModal {
  isOpen: boolean
  onRequestClose: () => void
  colaborador: IEmployee
}

interface iEixosPesquisa {
  lider: IEmployee[]
  pares: IEmployee[]
  outros: IEmployee[]
  altaLideranca: IEmployee[]
  liderados: IEmployee[]
}

export const ModalGestaoPesquisa: React.FC<iModal> = ({ onRequestClose, isOpen, colaborador }) => {
  const toast = useToast()
  const [Colaborador, setColaborador] = useState<IEmployee>()
  const [Tipo, setTipo] = useState<number>()
  const [Model, setModel] = useState<iEixosPesquisa>({ altaLideranca: [], lider: [], liderados: [], outros: [], pares: [] })
  const [ModalAddParticipanteIsOpen, setModalAddParticipanteIsOpen] = useState(false)
  function onOpenModal(tipo: number, colab?: IEmployee): void {
    setModalAddParticipanteIsOpen(true)
    setTipo(tipo)
    setColaborador(colab)
  }

  function GetJaAdicionados(): IEmployee[] {
    const response: IEmployee[] = []

    Model.altaLideranca.forEach((e) => response.push(e))
    Model.lider.forEach((e) => response.push(e))
    Model.liderados.forEach((e) => response.push(e))
    Model.outros.forEach((e) => response.push(e))
    Model.pares.forEach((e) => response.push(e))

    return response
  }

  function GetParticipantes(): void {
    appApi.get(`Colaborador/${colaborador.id}/GestaoParticipantes`)
      .then((res) => setModel(res.data))
      .catch((err) => console.log(err))
  }

  function AddAvaliador(form: iPostAvaliador): void {
    if (!Tipo) return
    const response: iPostAvaliador = { ...form, TipoAvaliador: Tipo }

    appApi.post(`Colaborador/${colaborador.id}/GestaoParticipantes`, response)
      .then(() => GetParticipantes())
      .catch((err) => console.log(err))
      .finally(() => {
        onCloseAdd()
        toast({
          title: 'Participante adicionado com sucesso',
          status: 'success',
          duration: 4000,
          position: 'top'
        })
      })
  }

  function UpdateAvaliador(form: iPostAvaliador, email: string): void {
    if (!Tipo) return
    const response: iPostAvaliador = { ...form, TipoAvaliador: Tipo }

    appApi.patch(`Colaborador/${colaborador.id}/GestaoParticipantes?emailAntigo=${email}`, response)
      .then(() => GetParticipantes())
      .catch((err) => console.log(err))
      .finally(() => {
        onCloseAdd()
        toast({
          title: 'Participante atualizado com sucesso',
          status: 'success',
          duration: 4000,
          position: 'top'
        })
      })
  }

  function DeleteAvaliador(form: iPostAvaliador): void {
    appApi.delete(`Colaborador/${colaborador.id}/GestaoParticipantes?email=${form.email}&colaboradorId=${form.colaboradorId ? form.colaboradorId : ''}`)
      .then(() => GetParticipantes())
      .catch((err) => console.log(err))
      .finally(() => {
        onCloseAdd()
        toast({
          title: 'Participante excluído com sucesso',
          status: 'success',
          duration: 4000,
          position: 'top'
        })
      })
  }

  function onCloseAdd(): void {
    setModalAddParticipanteIsOpen(false)
    setColaborador(undefined)
  }

  useEffect(() => {
    GetParticipantes()
  }, [])

  return (
    <ModalBase
      Titulo='Ajustar participantes das futuras pesquisas'
      isOpen={isOpen}
      onClose={onRequestClose}
      Width='48rem'
    >
      {ModalAddParticipanteIsOpen && (
        <ModalAddParticipante
          jaAdicionados={GetJaAdicionados()}
          isOpen={ModalAddParticipanteIsOpen}
          onConfirm={AddAvaliador}
          onRequestClose={onCloseAdd}
          onUpdateAvaliador={UpdateAvaliador}
          onDeleteAvaliador={DeleteAvaliador}
          colaborador={Colaborador}
        />
      )}
      <ModalBody>
        <Flex flexDir={'column'} padding='.5rem .75rem 0rem .75rem' gap={'.5rem'}>
            <SectionAssociados>
                <HeaderSection>
                    <h4>Gestor({Model.lider !== null ? Model.lider?.length : 0})</h4>
                    <ButtonCmp VarColor='Rosa' onClick={() => onOpenModal(1)}>Adicionar</ButtonCmp>
                </HeaderSection>
                <SectionBody>
                    {
                        Model.lider?.map((e, i) => {
                          return (
                                <CardAssociado
                                    key={i}
                                    avaliador={e}
                                    onEdit={(e) => onOpenModal(1, e)}
                                    tipo={1}
                                />
                          )
                        })
                    }
                </SectionBody>
            </SectionAssociados>

            <SectionAssociados>
                <HeaderSection>
                    <h4>Pares({Model.pares !== null ? Model.pares?.length : 0})</h4>
                    <ButtonCmp VarColor='Rosa' onClick={() => onOpenModal(2)}>Adicionar</ButtonCmp>
                </HeaderSection>
                <SectionBody>
                    {
                        Model.pares?.map((e, i) => {
                          return (
                                <CardAssociado
                                    key={i}
                                    avaliador={e}
                                    onEdit={(e) => onOpenModal(2, e)}
                                    tipo={2}
                                />
                          )
                        })
                    }
                </SectionBody>
            </SectionAssociados>

            <SectionAssociados>
                <HeaderSection>
                    <h4>Outros({Model.outros !== null ? Model.outros?.length : 0})</h4>
                    <ButtonCmp VarColor='Rosa' onClick={() => onOpenModal(4)}>Adicionar</ButtonCmp>
                </HeaderSection>
                <SectionBody>
                    {
                        Model.outros?.map((e, i) => {
                          return (
                                <CardAssociado
                                    key={i}
                                    avaliador={e}
                                    onEdit={(e) => onOpenModal(4, e)}
                                    tipo={4}
                                />
                          )
                        })
                    }
                </SectionBody>
            </SectionAssociados>

            <SectionAssociados>
                <HeaderSection>
                    <h4>Alta liderança({Model.altaLideranca !== null ? Model.altaLideranca?.length : 0})</h4>
                    <ButtonCmp VarColor='Rosa' onClick={() => onOpenModal(6)}>Adicionar</ButtonCmp>
                </HeaderSection>
                <SectionBody>
                    {
                        Model.altaLideranca?.map((e, i) => {
                          return (
                                <CardAssociado
                                    key={i}
                                    avaliador={e}
                                    onEdit={(e) => onOpenModal(6, e)}
                                    tipo={6}
                                />
                          )
                        })
                    }
                </SectionBody>
            </SectionAssociados>

            <SectionAssociados>
                <HeaderSection>
                    <h4>Liderados({Model.liderados !== null ? Model.liderados?.length : 0})</h4>
                    <ButtonCmp VarColor='Rosa' onClick={() => onOpenModal(3)}>Adicionar</ButtonCmp>
                </HeaderSection>
                <SectionBody>
                    {
                      Model.liderados?.map((e, i) => {
                        return (
                              <CardAssociado
                                  key={i}
                                  avaliador={e}
                                  onEdit={(e) => onOpenModal(3, e)}
                                  tipo={3}
                              />
                        )
                      })
                    }
                </SectionBody>
            </SectionAssociados>
        </Flex>
      </ModalBody>
      <Flex justifyContent={'end'} padding='1rem' gap={'.5rem'}>
        <ButtonCmp
          onClick={onRequestClose}
          VarColor='c6'
        >Voltar</ButtonCmp>

        <ButtonCmp
          onClick={onRequestClose}
          VarColor='Green2'
        >Salvar</ButtonCmp>
      </Flex>
    </ModalBase>
  )
}
