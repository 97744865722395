import { Flex, Switch, Text } from '@chakra-ui/react'

interface iProps {
  value: boolean
  isDisabled?: boolean
  id?: string
  legendaEsquerda?: string
  legendaDireita?: string
  OnChange: (e: boolean) => void
  onBlur?: () => void
}

export const SwitchCmp: React.FC<iProps> = ({ id, onBlur, value, legendaDireita, legendaEsquerda, OnChange, isDisabled }) => {
  return (
    <Flex alignItems={'center'} gap={'.625rem'} fontSize={'.875rem'}>
      {legendaEsquerda && <Text color={!value ? 'var(--Azul)' : 'black'} fontWeight={!value ? '600' : ''}>{legendaEsquerda}</Text>}
      <Switch
        id={id}
        _active={{ border: 'none', boxShadow: 'none', outline: 'none' }}
        _focus={{ border: 'none', boxShadow: 'none', outline: 'none' }}
        isChecked={value}
        isDisabled={isDisabled}
        onBlur={onBlur}
        onChange={(e) => OnChange(e.target.checked)}
      />
      {legendaDireita && <Text color={value ? 'var(--Azul)' : 'black'} fontWeight={value ? '600' : ''}>{legendaDireita}</Text>}
    </Flex>
  )
}
