/* eslint-disable @typescript-eslint/restrict-plus-operands */
import { Flex } from '@chakra-ui/react'
import ApexCharts from 'apexcharts'
import { useEffect } from 'react'
import { iGrafico } from '../../../../../interfaces'

interface iProps{
  id: string
  grafico: iGrafico
}

export const ColumnChart: React.FC<iProps> = ({ id, grafico }) => {
  function GerarGrafico(): void {
    const options = {
      series: [{
        name: '',
        data: grafico.dados[0].medias
      }],
      grid: {
        show: false
      },
      chart: {
        type: 'bar'
      },
      plotOptions: {
        bar: {
          color: '#4472C4',
          columnWidth: '70%',
          dataLabels: {
            position: 'top'
          }
        }
      },
      tooltip: {
        enabled: true,
        y: {
          formatter: function (val: number) {
            return val.toFixed(2)
          }
        }
      },
      dataLabels: {
        enabled: true,
        style: {
          fontSize: '12px',
          colors: ['#58544D']
        },
        offsetY: -20,
        formatter: function(value: number): string {
          return `${value.toFixed(2)}`
        }
      },
      // title: {
      //   text: 'Separado por'
      // },
      annotations: {
        yaxis: [
          {
            y: 0,
            strokeDashArray: 0,
            borderColor: 'var(--c5)',
            fillColor: '#c2c2c2',
            opacity: 0.8,
            offsetX: 0,
            offsetY: 0
          }
        ]
      },
      yaxis: {
        show: false
        // labels: {
        //   formatter: function (y: any) {
        //     return y.toFixed(0) + '%'
        //   }
        // }
      },
      xaxis: {
        categories: grafico.competencias
      }
    }
    const chart = new ApexCharts(document.querySelector(`#chart-${id}`), options)
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    chart.render()

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    chart.updateSeries([{
      name: '',
      data: grafico.dados[0].medias
    }])
  }

  useEffect(() => {
    GerarGrafico()
  }, [grafico])
  return (
        <Flex width={'100%'} h={'70%'} id={`chart-${id}`}/>
  )
}
