/* eslint-disable @typescript-eslint/no-floating-promises */
import ApexCharts from 'apexcharts'
import { Flex } from '@chakra-ui/react'
import { useEffect } from 'react'
import { iIndicador, iPeriodo } from '../..'

interface BarChartProps {
  id: string
  // valorMaximo: number
  indicador?: iIndicador
  periodoInicial?: number
  periodoFinal?: number
}

export const IndicadorVariosPeriodosChart: React.FC<BarChartProps> = ({ id, indicador, periodoInicial, periodoFinal }) => {
  function GetAcumulado(periodo: iPeriodo, index: number): number {
    let response = 0
    for (let i = 0; i < index + 1; i++) {
      response += periodo.meses[i].real ?? 0
    }

    return response
  }

  function FormatarNumero(num: number, indicador?: iIndicador): string {
    let response = ''
    const toFixed = indicador?.tipoMeta === 5 || indicador?.tipoMeta === 0

    const numero = toFixed ? num.toFixed(2) : num.toString()

    const values = numero.split('.')

    for (let i = 0; i < (values[0].length / 3); i++) {
      for (let index = 1; index < 4; index++) {
        if (values[0][values[0].length - index - (i * 3)]) {
          response = values[0][values[0].length - index - (i * 3)] + response
        }
      }
      if ((i + 1) < (values[0].length / 3)) {
        response = '.' + response
      }
    }

    if (toFixed) {
      response += `,${values[1]}`
    }
    return response
  }

  function ReturnSeries(): any[] {
    const response: any[] = []
    indicador?.periodos.filter(e => {
      if (periodoInicial) {
        if (periodoInicial > e.ano) {
          return null
        }
      }
      if (periodoFinal) {
        if (periodoFinal < e.ano) {
          return null
        }
      }

      return e
    }).forEach((e) => {
      response.push({
        name: 'Valor Acumulado' + ` - ${e.ano}`,
        data: e.meses.map((m, i) => {
          return GetAcumulado(e, i)
        })
      })
      response.push({
        name: 'Valor Previsto' + ` - ${e.ano}`,
        data: e.meses.map((m, i) => {
          return m.previsto
        })
      })
    })
    return response
  }

  const mesesAbreviados = [
    'Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun',
    'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'
  ]
  function GerarGrafico(): void {
    const options = {
      chart: {
        height: 350,
        type: 'line',
        stacked: false,
        zoom: {
          enabled: false
        }
      },
      markers: {
        size: 6
      },
      dataLabels: {
        enabled: false
      },
      colors: ['#27AE60', '#F2994A', '#2ECC71', '#E67E22', '#1ABC9C', '#F39C12', '#16A085', '#D35400', '#82E0AA', '#F7DC6F', '#145A32', '#B9770E'],
      series: ReturnSeries(),
      stroke: {
        width: [4, 4]
      },
      plotOptions: {
        bar: {
          columnWidth: '20%'
        }
      },
      // yaxis: {
      //   max: valorMaximo
      // },
      xaxis: {
        categories: indicador?.periodos[0].meses.map(e => e.numeroMes).map(e => mesesAbreviados[e - 1])
      },
      yaxis: {
        labels: {
          formatter: function(value: any) {
            return indicador?.tipoMeta === 0 ? `${indicador?.medidaMeta ?? ''}${FormatarNumero(value, indicador)}` : `${FormatarNumero(value, indicador)}${indicador?.medidaMeta ?? ''}`
          }
        }
      },
      tooltip: {
        shared: false,
        intersect: true,
        x: {
          show: false
        },
        y: {
          show: true,
          formatter: function(value: any) {
            return indicador?.tipoMeta === 0 ? `${indicador?.medidaMeta ?? ''}${FormatarNumero(value, indicador)}` : `${FormatarNumero(value, indicador)}${indicador?.medidaMeta ?? ''}`
          }
        }
      },
      legend: {
        horizontalAlign: 'left',
        offsetX: 40,
        itemMargin: {
          horizontal: 8,
          vertical: 4
        }
      }
    }
    const chart = new ApexCharts(document.querySelector(`#chart-varios-periodos-${id}`), options)
    chart.render()
  }

  useEffect(() => {
    GerarGrafico()
  }, [])
  return (
      <Flex id={`chart-varios-periodos-${id}`} />
  )
}
