/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
/* eslint-disable @typescript-eslint/no-floating-promises */
import { Box, CircularProgress, CircularProgressLabel, Divider, Flex, FormControl, FormLabel, Grid, Slider, SliderFilledTrack, SliderThumb, SliderTrack, Text, useToast } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { FaCheck, FaCheckCircle, FaClock, FaPauseCircle, FaRegCommentDots, FaSpinner, FaTrash, FaUserTag } from 'react-icons/fa'
import { useLocation, useParams } from 'react-router-dom'
import { FindUser } from '../../../../components/FindBox'
import { iNomeId } from '../../../../interfaces'
import { FormatarMensagemEditorDeTexto, PropInvalida } from '../../../../Utils/Helper'
import { Emoji } from '../../Avaliacao/Components/Emoji'
import { iAvaliacoes, iModelAcao, iProgressoXAcao } from '../../Criar'
import { ComentarioContainer, ComentariosContainer, OutlineContainer } from '../../Criar/styles'

import IconeLove from '../../../../assets/IconeLove.svg'
import IconeMagoado from '../../../../assets/IconeMagoado.svg'
import IconeFeliz from '../../../../assets/IconeFeliz.svg'
import IconePiscada from '../../../../assets/IconePiscada.svg'
import IconeTriste from '../../../../assets/IconeTriste.svg'
import { InputCmp } from '../../../../components/InputCmp'
import { TextAreaCmp } from '../../../../components/TextareaCmp'
import { CheckboxCmp } from '../../../../components/CheckboxCmp'
import { LiaAngleDoubleDownSolid, LiaAngleDoubleUpSolid } from 'react-icons/lia'
import { ButtonCmp } from '../../../../components/ButtonCmp'
import { appApi } from '../../../../services/appApi'
import LoadingContainer from '../../../../components/LoadingContainer'
import { ModalDelete } from '../../../../components/ModalDelete'
import { ModalConcluirAcao } from '../ModalConcluirAcao'

interface iAcaoProps {
  meuPDI: boolean
  acao: iModelAcao
  avaliacoes: iAvaliacoes[]
  competencias: iNomeId[]
  canEdit: boolean
  position: number
  onChangeProgresso: (acaoId: string, progresso: number, observacaoProgresso: string) => void
  onRemoveProgresso: (acaoId: string) => void
  onRemove: (id: string) => void
  onUpdate: (acao: iModelAcao) => void
  OnReload: () => void
}

export const AcaoPDI: React.FC<iAcaoProps> = ({ meuPDI, acao, competencias, canEdit, position, avaliacoes, onRemove, onUpdate, OnReload, onChangeProgresso, onRemoveProgresso }) => {
  const { pathname } = useLocation()
  const { pdiId } = useParams<{ pdiId: string }>()
  const toast = useToast()

  const [isInvalid, setIsInvalid] = useState(false)
  const [IsLoading, setIsLoading] = useState(false)
  const [ConcluirAcaoIsOpen, setConcluirAcaoIsOpen] = useState(false)
  const [ProgressoIsOpen, setProgressoIsOpen] = useState(false)
  const [EncerrarAcaoIsOpen, setEncerrarAcaoIsOpen] = useState(false)
  const [ObservacaoProgresso, setObservacaoProgresso] = useState<string>()
  const [PorcentagemProgresso, setPorcentagemProgresso] = useState<number | undefined>((acao?.progressos && acao.progressos[0]) ? acao.progressos[0].progresso : undefined)
  const [Model, setModel] = useState<iModelAcao>({
    id: '',
    colaboradorAcao: false,
    dataFim: '',
    assunto: '',
    tipo: 0,
    status: 0
  })

  function ConcluirAcao(comProgresso: boolean): void {
    appApi.post(`PDI/${pdiId}/Acao/${acao.id}/Finalizar?comProgresso=${comProgresso}`, { texto: '' })
      .then(() => {
        toast({
          title: 'Ação concluída com sucesso',
          duration: 3000,
          status: 'success',
          position: 'top'
        })
        OnReload()
        if (comProgresso) {
          setPorcentagemProgresso(100)
        }
      })
      .catch(err => console.log(err))
  }

  function ReabrirAcao(): void {
    appApi.post(`PDI/${pdiId}/Acao/${acao.id}/Reabrir`)
      .then(() => {
        setModel({ ...Model, status: 5 })
        toast({
          title: 'Ação reaberta com sucesso',
          duration: 3000,
          status: 'success',
          position: 'top'
        })
        OnReload()
      })
      .catch(err => console.log(err))
  }

  function SelecionarCompetencia(id: string): void {
    onUpdate({
      colaboradorAcao: Model.colaboradorAcao,
      dataFim: Model.dataFim,
      id: Model.id,
      tipo: Model.tipo,
      dataInicio: Model.dataInicio,
      descricao: Model.descricao,
      motivo: Model.motivo,
      hora: Model.hora,
      titulo: Model.titulo,
      assunto: Model.assunto,
      competenciaId: id === '' ? undefined : id,
      status: Model.status,
      atualizacao: Model.atualizacao
    })
  }

  function TrocarVerbalização(texto: string): string {
    if (!meuPDI) {
      return texto
    }

    if (texto === 'O que fazer?') {
      return 'O que vou fazer'
    } else if (texto === 'Horas para dedicar') {
      return 'Horas a dedicar'
    }

    return ''
  }

  const handleCheckColaboradorEditar = (value: boolean): void => {
    if (value) {
      onUpdate({
        id: Model.id,
        colaboradorAcao: value,
        assunto: Model.assunto,
        competenciaId: Model.competenciaId,
        dataFim: Model.dataFim,
        tipo: Model.tipo,
        status: Model.status,
        atualizacao: Model.atualizacao
      })
    } else {
      onUpdate({
        colaboradorAcao: value,
        competenciaId: Model.competenciaId,
        dataFim: Model.dataFim,
        id: Model.id,
        tipo: Model.tipo,
        dataInicio: Model.dataInicio,
        descricao: Model.descricao,
        motivo: Model.motivo,
        hora: Model.hora,
        titulo: Model.titulo,
        assunto: Model.assunto,
        status: Model.status,
        atualizacao: Model.atualizacao
      })
    }
  }

  function GetColorProgresso(e?: number): string {
    const variavel = e ?? PorcentagemProgresso ?? 0
    if (variavel < 25) {
      return 'var(--Red)'
    } else if (variavel < 50) {
      return 'var(--Orange)'
    } else if (variavel < 75) {
      return 'var(--Yellow)'
    } else {
      return 'var(--Green2)'
    }
  }

  function ReturnNotaPDI(acaoId: string): any {
    if (!avaliacoes) {
      return <></>
    }
    const notas = avaliacoes?.find(e => e.notas.some(a => a.acaoId === acaoId))?.notas
    if (notas) {
      const notaFinal = notas.find(e => e.acaoId === acaoId)?.nota
      switch (notaFinal) {
        case 1:
          return (
            <Flex fontSize={'14px'} gap={'.325rem'} alignItems={'center'} fontWeight={'500'} mt={'.75rem'}>
                <Flex h={'25px'}>Avaliação final:</Flex>
                <Emoji
                flexDir='row'
                texto='Não melhorou em nada'
                icon={IconeTriste}
                />
            </Flex>
          )
        case 2:
          return (
            <Flex fontSize={'14px'} gap={'.325rem'} alignItems={'center'} fontWeight={'500'} mt={'.75rem'}>
                <Flex h={'25px'}>Avaliação final:</Flex>
                <Emoji
                flexDir='row'
                texto='Precisa se esforçar mais'
                icon={IconeMagoado}
                />
            </Flex>
          )
        case 3:
          return (
            <Flex fontSize={'14px'} gap={'.325rem'} alignItems={'center'} fontWeight={'500'} mt={'.75rem'}>
                <Flex h={'25px'}>Avaliação final:</Flex>
                <Emoji
                flexDir='row'
                texto='Está no caminho certo'
                icon={IconeFeliz}
                />
            </Flex>
          )
        case 4:
          return (
            <Flex fontSize={'14px'} gap={'.325rem'} alignItems={'center'} fontWeight={'500'} mt={'.75rem'}>
                <Flex h={'25px'}>Avaliação final:</Flex>
                <Emoji
                flexDir='row'
                texto='Já melhorou muito'
                icon={IconePiscada}
                />
            </Flex>
          )
        case 5:
          return (
            <Flex fontSize={'14px'} gap={'.325rem'} alignItems={'center'} fontWeight={'500'} mt={'.75rem'}>
                <Flex h={'25px'}>Avaliação final:</Flex>
                <Emoji
                    flexDir='row'
                    texto='Já atingiu o esperado'
                    icon={IconeLove}
                />
            </Flex>
          )
        default:
          return <></>
      }
    }
    return <></>
  }

  function onSendProgresso(): void {
    setIsLoading(true)
    const data: iProgressoXAcao = { acaoId: acao.id, progresso: PorcentagemProgresso ?? 0, comentario: ObservacaoProgresso ?? '' }
    appApi.put(`PDI/${pdiId}/Acao/Progresso`, data)
      .then((res) => {
        const newArr = [res.data]
        Model.progressos?.forEach(e => newArr.push(e))
        setModel({
          ...Model,
          progressos: newArr
        })
        toast({
          title: 'Atualização de progresso realizada com sucesso',
          status: 'success',
          isClosable: false,
          position: 'top-right'
        })
        if (PorcentagemProgresso === 100) {
          setEncerrarAcaoIsOpen(true)
        } else {
          setObservacaoProgresso('')
        }
        setPorcentagemProgresso(0)
      })
      .catch((err) => console.log(err))
      .finally(() => { setIsLoading(false); onRemoveProgresso(acao.id) })
  }

  function EncerrarAcao(): void {
    appApi.post(`PDI/${pdiId}/Acao/${Model.id}/Finalizar`, { texto: ObservacaoProgresso })
      .then((res) => {
        setModel({
          ...Model,
          status: 7
        })
        toast({
          title: 'Ação encerrada com sucesso',
          status: 'success',
          isClosable: false,
          position: 'top-right'
        })
        setObservacaoProgresso('')
      })
      .catch((err) => console.log(err))
      .finally(() => setEncerrarAcaoIsOpen(false))
  }

  useEffect(() => {
    setModel(acao)
  }, [acao])

  useEffect(() => {
    if ((PorcentagemProgresso || ObservacaoProgresso) && ((PorcentagemProgresso !== (acao.progressos?.[0] ? acao.progressos[0].progresso : 0)) || ObservacaoProgresso)) {
      onChangeProgresso(acao.id, PorcentagemProgresso ?? 0, ObservacaoProgresso ?? '')
    }
  }, [PorcentagemProgresso, ObservacaoProgresso])

  return (
      <Flex flexDir={'column'} alignItems='center' >
        <ModalDelete
          isOpen={EncerrarAcaoIsOpen}
          message={'Deseja encerrar esta ação?'}
          onConfirm={EncerrarAcao}
          onRequestClose={() => { setEncerrarAcaoIsOpen(false); setObservacaoProgresso('') }}
          center
        />
        <ModalConcluirAcao
          isOpen={ConcluirAcaoIsOpen}
          message={'Deseja concluir essa ação e colocar o progresso dela como 100%?'}
          onConfirm={() => ConcluirAcao(false)}
          onConfirmComProgresso={() => ConcluirAcao(true)}
          onRequestClose={() => { setConcluirAcaoIsOpen(false) }}
        />
        <OutlineContainer
            theme={acao.temErro ? 'Red' : 'Azul'}
        >
            {(Model.status === 0 || Model.status === 1) && (
                <Flex top={'.5rem'} right={'1rem'} pos={'absolute'} zIndex={'999'}>
                    <FaTrash onClick={() => onRemove(Model.id)}
                        cursor={'pointer'}
                        size={14} color='var(--Red)'
                    />
                </Flex>
            )}

            {(Model.tipo === 0) && (
                <Flex justifyContent={'center'} py={'1rem'}>
                    <Flex width={'100%'} flexDir={'column'} alignItems='center' pb={'.75rem'}>
                        <Text textAlign={'center'} marginBottom='.75rem' fontWeight={'bold'} color='var(--Gray1)' fontSize={'1.125rem'}>Como gostaria de criar esta ação?</Text>
                        <Grid width={'60%'} gap={'2%'} templateColumns='repeat(2, 1fr)'>
                            <Flex
                                onClick={() => setModel({ ...Model, tipo: 1 })}
                                color='var(--Azul)'
                                justifyContent={'center'}
                                cursor={'pointer'}
                                _hover={{
                                  color: 'var(--c1)',
                                  bg: 'var(--Azul)'
                                }}
                                alignItems={'center'}
                                border={'2px solid var(--Azul)'}
                                gap='.5rem' borderRadius='.25rem'
                                padding={'.5rem'}>
                                <FaUserTag size={34} />
                                <Text
                                    textAlign={'center'}
                                    fontSize={'1rem'}
                                    fontWeight={'600'}
                                >Através de uma competência</Text>
                            </Flex>
                            <Flex
                                onClick={() => setModel({ ...Model, tipo: 2 })}
                                color='var(--Azul)'
                                justifyContent={'center'}
                                cursor={'pointer'}
                                _hover={{
                                  color: 'var(--c1)',
                                  bg: 'var(--Azul)'
                                }}
                                alignItems={'center'}
                                border={'2px solid var(--Azul)'}
                                gap='.5rem' borderRadius='.25rem'
                                padding={'.5rem'}>
                                <FaRegCommentDots size={34} />
                                <Text textAlign={'center'} fontSize={'1rem'} fontWeight={'600'}>Assunto personalizado</Text>
                            </Flex>
                        </Grid>
                    </Flex>
                </Flex>
            )}

            {(Model.tipo > 0) && (
                <Flex gap={'1rem'}>
                    <Flex flexDir={'column'} color={'var(--c1) !important'} bg={'var(--Azul)'} gap={'2%'} p={'1rem'} minW={'33%'}>
                        <Text fontWeight={'800'} fontSize={'1.125rem'} lineHeight={'1.125rem'}>{position + 1}ª Tema a ser trabalhado</Text>
                        <Flex gap={'2%'} flexWrap={'wrap'}>
                            {
                                (Model.tipo === 2) && (
                                    <FormControl mt={'.25rem'} w={(acao.atualizacao?.length ?? 0) > 0 ? '100%' : '62%'}>
                                        <InputCmp
                                            label='Assunto a ser tratado'
                                            placeholder='Digite um assunto'
                                            isDisabled={Model.status === 7 || !canEdit}
                                            onBlur={(e) => onUpdate({
                                              ...Model,
                                              assunto: e.target.value
                                            })}
                                            value={Model.assunto}
                                            OnChange={(e) => setModel({ ...Model, assunto: e })}
                                            themeWhite
                                        />
                                    </FormControl>
                                )
                            }

                            {
                                (Model.tipo === 1) && (
                                    <FormControl mt={'.25rem'} w={(acao.atualizacao?.length ?? 0) > 0 ? '100%' : '62%'}>
                                        <FormLabel fontSize={'14px'} mb={'.25rem'} fontWeight={'700'}>Competência vinculada *</FormLabel>
                                        <FindUser
                                            isDisabled={Model.status === 7 || !canEdit}
                                            placeholder='Digite uma competência'
                                            lista={competencias}
                                            onChoice={SelecionarCompetencia}
                                            isNotUser
                                            selecionado={Model.competenciaId}
                                            themeWhite
                                            selectAuto
                                            onBlur={() => {
                                              if (PropInvalida(Model.competenciaId)) {
                                                setIsInvalid(true)
                                              } else {
                                                setIsInvalid(false)
                                              }
                                            }}
                                        />
                                    </FormControl>
                                )
                            }

                            {(!Model.colaboradorAcao) && (
                                <FormControl mt={(acao.atualizacao?.length ?? 0) > 0 ? '.5rem' : '.25rem'} w={(acao.atualizacao?.length ?? 0) > 0 ? '100%' : '36%'}>
                                  <InputCmp
                                      label={'Horas a dedicar'}
                                      marginRight={'.5rem'}
                                      type={'number'}
                                      min={1}
                                      isDisabled={Model.status === 7 || !canEdit}
                                      themeWhite
                                      value={Model.hora ?? ''}
                                      OnChange={(e) => setModel({ ...Model, hora: e === '0' ? '1' : e })}
                                      onBlur={(e) => onUpdate({
                                        ...Model,
                                        hora: e.target.value
                                      })}
                                  />
                                </FormControl>
                            )}
                            {isInvalid && <Text mt={'.5rem'} as='span' color={'var(--Red)'} fontSize={'12px'} fontWeight={700} filter={'brightness(1.3)'}>Nenhuma competência foi selecionada</Text>}
                        </Flex>
                        <Flex gap={'2%'} flexWrap={'wrap'}>
                          {
                              (!Model.colaboradorAcao) && (
                                  <FormControl mt={'.25rem'} width={(acao.atualizacao?.length ?? 0) > 0 ? '100%' : '49%'}>
                                      <InputCmp
                                          label='Data para iniciar *'
                                          value={Model.dataInicio ?? ''}
                                          isDisabled={Model.status === 7 || !canEdit}
                                          OnChange={(e) => setModel({ ...Model, dataInicio: e })}
                                          type={'date'}
                                          min={new Date().toISOString().split('T')[0]}
                                          themeWhite
                                          onBlur={(e) => onUpdate({
                                            ...Model,
                                            dataInicio: e.target.value
                                          })}
                                      />
                                  </FormControl>
                              )
                          }
                          <FormControl width={(acao.atualizacao?.length ?? 0) > 0 ? '100%' : '49%'} mt={(acao.atualizacao?.length ?? 0) > 0 ? '.5rem' : '.25rem'}>
                              <InputCmp
                                  label='Data para finalizar *'
                                  isDisabled={Model.status === 7 || !canEdit}
                                  type={'date'}
                                  max={'2099-12-31T23:59'}
                                  value={Model.dataFim ?? ''}
                                  min={Model.dataInicio ? Model.dataInicio : new Date().toISOString().split('T')[0]}
                                  OnChange={(e) => setModel({ ...Model, dataFim: e })}
                                  onBlur={(e) => onUpdate({
                                    ...Model,
                                    dataFim: e.target.value
                                  })}
                                  themeWhite
                              />
                          </FormControl>
                        </Flex>
                    </Flex>

                    <Flex gap={'2%'} flexDir={'column'} py={'1rem'} pr='1rem' w={'100%'}>
                        {(!meuPDI && !pathname.includes('Editar')) && (<Flex w={'100%'} mb={'.25rem'} justifyContent={'start'}>
                            <FormControl as={Flex}>
                                <CheckboxCmp
                                    isChecked={Model.colaboradorAcao}
                                    OnChange={(e) => handleCheckColaboradorEditar(e)}
                                >
                                  Colaborador deve inserir as ações
                                </CheckboxCmp>
                            </FormControl>
                        </Flex>)}
                        {(pathname.includes('Editar')) && (
                          <Flex alignItems={'end'} pos={'absolute'} top={'1.375rem'} right={'1rem'}>
                              {
                                  (Model.status === 1 || Model.status === 2) && (
                                      <Flex alignItems={'center'} color='var(--Yellow)' flexDir={'column'}>
                                          <FaPauseCircle size={28} />
                                          <Text marginTop={'4px'} fontSize={'13px'} width={'max-content'} fontWeight={'bold'}
                                          >
                                              Aguardando {Model.status === 1 ? 'aprovação' : 'alteração'}
                                          </Text>
                                      </Flex>
                                  )
                              }

                              {
                                  (Model.status === 5) && (
                                      <Flex alignItems={'center'} color='var(--Azul)' flexDir={'column'}>
                                          <FaSpinner size={28} />
                                          <Text marginTop={'4px'} fontSize={'13px'} width={'max-content'} fontWeight={'bold'}>Em andamento</Text>
                                      </Flex>
                                  )
                              }

                              {
                                  (Model.status === 7) && (
                                      <Flex alignItems={'center'} color='var(--Green2)' flexDir={'column'}>
                                          <FaCheckCircle size={28} />
                                          <Text marginTop={'4px'} fontSize={'13px'} width={'max-content'} fontWeight={'bold'}>Concluído</Text>
                                      </Flex>
                                  )
                              }

                              {
                                  (Model.status === 6) && (
                                      <Flex alignItems={'center'} color='var(--Red)' flexDir={'column'}>
                                          <FaClock size={28} />
                                          <Text marginTop={'4px'} fontSize={'13px'} width={'max-content'} fontWeight={'bold'}>Atrasada</Text>
                                      </Flex>
                                  )
                              }
                          </Flex>
                        )}
                        <FormControl w={Model.status !== 0 ? '89%' : '100%'}>
                            <InputCmp
                                label={`${TrocarVerbalização('O que fazer?')} *`}
                                w={Model.status !== 0 ? '89%' : '100%'}
                                value={Model.titulo ?? ''}
                                isDisabled={Model.status === 7 || !canEdit || Model.colaboradorAcao}
                                OnChange={(e) => setModel({ ...Model, titulo: e })}
                                placeholder='Adicione um titulo curto' borderColor={'var(--Gray4)'}
                                onBlur={(e) => onUpdate({
                                  ...Model,
                                  titulo: e.target.value
                                })}
                            />
                        </FormControl>

                        <Flex gap={'2%'} mt={'.5rem'}>
                          <FormControl>
                              <TextAreaCmp
                                  label='Como será realizada essa ação? *'
                                  value={Model.descricao ?? ''}
                                  isDisabled={Model.status === 7 || !canEdit || Model.colaboradorAcao}
                                  OnChange={(e) => setModel({ ...Model, descricao: e })}
                                  placeholder='Adicione uma descrição detalhada da ação'
                                  borderColor={'var(--Gray4)'}
                                  onBlur={(e) => onUpdate({
                                    ...Model,
                                    descricao: e.target.value
                                  })}
                              />
                          </FormControl>
                          {/*
                          <FormControl>
                                          <TextAreaCmp
                                              label='Qual o motivo para se trabalhar essa competência?'
                                              value={Model.motivo ?? ''}
                                              isDisabled={Model.status === 7 || !canEdit}
                                              OnChange={(e) => setModel({ ...Model, motivo: e })}
                                              placeholder='Adicione uma descrição detalhada do motivo'
                                              borderColor={'var(--Gray4)'}
                                              onBlur={(e) => onUpdate({
                                                ...Model,
                                                motivo: e.target.value
                                              })}

                                          />
                          </FormControl> */}
                        </Flex>

                        {ReturnNotaPDI(acao.id)}

                        {(acao.atualizacao?.filter(e => e.comentario)?.length ?? 0) > 0 && (
                            <Box mt={'.5rem'}>
                                <FormLabel fontSize={'.875rem'} fontWeight={500} mb={'.25rem'}>Comentários</FormLabel>
                                <ComentarioContainer>
                                    {acao.atualizacao?.some(e => e.tipoComentario === 0 && e.comentario !== null) && (
                                        <Flex direction={'column'}>
                                            {acao.atualizacao?.filter(e => e.tipoComentario === 0 && e.comentario)?.map((e, i) => {
                                              return (
                                                    <ComentariosContainer key={i}>
                                                        <strong dangerouslySetInnerHTML={{ __html: FormatarMensagemEditorDeTexto(e.comentario ?? '') }}></strong>
                                                        <span style={{ fontSize: '14px' }}>Comentário de {e.tipoComentario === 0 && 'aprovação'} enviado por {e.autor} em {e.data} às {e.horario}</span>
                                                    </ComentariosContainer>
                                              )
                                            })}
                                        </Flex>
                                    )}
                                    {acao.atualizacao?.some(e => e.tipoComentario === 1) && (
                                        <Flex direction={'column'}>
                                            {acao.atualizacao?.filter(e => e.tipoComentario === 1 && e.comentario)?.map((e, i) => {
                                              return (
                                                    <ComentariosContainer key={i}>
                                                        <strong dangerouslySetInnerHTML={{ __html: FormatarMensagemEditorDeTexto(e.comentario ?? '') }}></strong>
                                                        <span style={{ fontSize: '14px' }}>Comentário de {e.tipoComentario === 1 && 'ajuste'} enviado por {e.autor} em {e.data} às {e.horario}</span>
                                                    </ComentariosContainer>
                                              )
                                            })}
                                        </Flex>
                                    )}
                                    {acao.atualizacao?.some(e => e.tipoComentario === 2) && (
                                        <Flex direction={'column'}>
                                            {acao.atualizacao?.filter(e => e.tipoComentario === 2 && e.comentario)?.map((e, i) => {
                                              return (
                                                    <ComentariosContainer key={i}>
                                                        <strong dangerouslySetInnerHTML={{ __html: FormatarMensagemEditorDeTexto(e.comentario ?? '') }}></strong>
                                                        <span style={{ fontSize: '14px' }}>Comentário de {e.tipoComentario === 2 && 'andamento'} enviado por {e.autor} em {e.data} às {e.horario}</span>
                                                    </ComentariosContainer>
                                              )
                                            })}
                                        </Flex>

                                    )}
                                    {acao.atualizacao?.length === 0 && (
                                        <h3>Sem comentários realizados no PDI</h3>
                                    )}
                                </ComentarioContainer>
                            </Box>
                        )}
                        {Model.status === 5 && <Flex justifyContent={'end'} h={'2.5rem'} alignItems={'end'}><ButtonCmp w={'10rem'} VarColor='Green2' onClick={() => setConcluirAcaoIsOpen(true)}>Concluir ação</ButtonCmp></Flex>}
                        {Model.status === 7 && <Flex justifyContent={'end'} h={'2.5rem'} alignItems={'end'}><ButtonCmp w={'10rem'} VarColor='Green2' onClick={ReabrirAcao}>Reabrir ação</ButtonCmp></Flex>}
                    </Flex>
                </Flex>
            )}
        </OutlineContainer>
        <Flex transition={'.4s'} flexDir={'column'} h={IsLoading ? 'fit-content' : ProgressoIsOpen ? `${220 + ((Model.progressos && Model.progressos.length > 0) ? 37 + (Model.progressos?.length * 133) : 0)}px` : '0px'} p={ProgressoIsOpen ? '1rem' : '0'} border={ProgressoIsOpen ? '2px solid var(--c4)' : undefined} borderTop={'none'} boxSizing={'border-box'} overflow={'hidden'} w={'full'} gap={'1rem'}>
          <LoadingContainer loading={IsLoading} linhas={6}>
            <Flex flexDir={'column'} w='100%' gap={'.75rem'} alignItems='center'>
              <TextAreaCmp label='Escreva uma observação de como está o andamento desta ação' value={ObservacaoProgresso} OnChange={setObservacaoProgresso} placeholder='Escreva seu comentário sobre seu progresso nessa ação'/>
              <Flex gap={'1rem'} width={'100%'} pl={'6px'}>
                <Slider w={'99%'} value={PorcentagemProgresso} onChange={setPorcentagemProgresso}>
                  <SliderTrack bg={'var(--c5)'} height={'6px'}>
                    <SliderFilledTrack bg={GetColorProgresso()} transition={'.1s'} height={'6px'} />
                  </SliderTrack>
                  <SliderThumb boxSize={6} borderColor={GetColorProgresso()} >
                    <Box color={GetColorProgresso()} transition={'.3s'} as={FaCheck} />
                  </SliderThumb>
                </Slider>
                <Text color={GetColorProgresso()} transition={'.3s'} fontWeight={'700'}>{PorcentagemProgresso}%</Text>
              </Flex>
              <Flex gap={'.5rem'}>
                <ButtonCmp VarColor='Green2' onClick={onSendProgresso}>Enviar atualização de progresso</ButtonCmp>
              </Flex>
            </Flex>
            {Model.progressos && Model.progressos.length > 0 && <Flex flexDir={'column'} gap={'1.25rem'}>
              <Text fontWeight={'700'} fontSize={'14px'}>Histórico de progressos enviados:</Text>
              {Model.progressos?.map((e, i) => {
                return (
                  <>
                    {i !== 0 && <Divider borderColor={'var(--c6)'} opacity={'1'}/>}
                    <Flex gap={'1.5rem'} w={'100%'}>
                    {
                      <Flex flex='1' p={'.5rem 1rem'} flexDir='column' justifyContent={'space-between'} fontSize='14px' bg={'var(--c2)'} border={'1px solid var(--c6)'}>
                        <span dangerouslySetInnerHTML={{ __html: FormatarMensagemEditorDeTexto(e.comentario ?? '') }}></span>
                        <Text fontSize={'13px'}>Enviado por <strong>{e.colaborador?.nome}</strong> no dia <strong>{e.data}</strong> às <strong>{e.hora}</strong></Text>
                      </Flex>}
                      <Flex flexDir={'column'} alignItems={'center'} justifyContent={'center'} mb={'-.625rem'}>
                        <Text fontWeight={'700'}fontSize={'14px'}>Progresso realizado</Text>
                        <CircularProgress size={'3.5rem'} value={e.progresso} color={GetColorProgresso(e.progresso)}>
                          <CircularProgressLabel top={'43%'}>{e.progresso}%</CircularProgressLabel>
                        </CircularProgress>
                      </Flex>
                    </Flex>
                  </>
                )
              })}
            </Flex>}
          </LoadingContainer>
        </Flex>
        {(Model.status === 7 || Model.status === 3 || Model.status === 5 || Model.status === 6) && <Flex onClick={() => setProgressoIsOpen(!ProgressoIsOpen)} justifyContent={'center'} alignItems={'center'} p={'.325rem 1rem'} w={'12rem'} background={'var(--Green2Opaco)'} fontSize='19px' _hover={{ background: 'var(--Green2)', color: 'var(--c1)' }} cursor={'pointer'} borderRadius={'0 0 .75rem .75rem'}>
          {ProgressoIsOpen ? <LiaAngleDoubleUpSolid /> : <LiaAngleDoubleDownSolid />}
        </Flex>}
      </Flex>
  )
}
