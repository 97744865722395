/* eslint-disable @typescript-eslint/consistent-type-assertions */
/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Avatar, Box, Divider, Flex, Img, Text } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { AiOutlineStop } from 'react-icons/ai'
import { FaChartBar, FaCheckCircle, FaClipboardList, FaCog, FaRegPlayCircle, FaRegStopCircle, FaRegTimesCircle } from 'react-icons/fa'
import { ButtonCmp } from '../../../../../components/ButtonCmp'
import { parseJwt } from '../../../../../services/token'
import { CardAvaliado } from '../CardAvaliado'
import { ColumnText } from '../CardAvaliado/styles'

import imgAvaliadores from '../../../../../assets/IAvaliadores.svg'
import { ConverterDataHora, PropInvalida, SubstringRoute } from '../../../../../Utils/Helper'
import { useLocation, useNavigate } from 'react-router-dom'

interface iColaboradorBase {
  id: string
  nome: string
  avatar: string
  nomeDepartamento: string
  gestorId: string
  gestorNome?: string
  departamentoId: string
}

export interface iAvaliado {
  id: string
  colaborador: iColaboradorBase
  totalAvaliadores: number
  totalRespostas: number
  autoavaliacao?: boolean
  gestor?: boolean
  encerrada: boolean
  iniciada: boolean
  dataInicio?: string
  dataFim?: string
  liberarResultado: number
  interrompida: boolean
  pdi: boolean
  pdiAtrasado?: boolean
  apenasAutoAvaliacao: boolean
}

interface iStatus{
  texto: string
  cor: string
  data: string
}

interface iProps {
  avaliacoes: iAvaliado[]
  pesquisaEncerrada: boolean
  diasPdi?: number
  onEncerrar: (id: string) => void
  onReabrir: (id: string) => void
  openConfiguracao?: (avaliado: iAvaliado) => void
  onOpenModalReabrir: (id: string) => void
  onOpenModalInterromper: (id: string) => void
}

export const CardAvaliadoCiclico: React.FC<iProps> = ({ avaliacoes, pesquisaEncerrada, diasPdi, onEncerrar, onReabrir, openConfiguracao, onOpenModalReabrir, onOpenModalInterromper }) => {
  const [IsDisabled, setIsDisabled] = useState(false)
  const interrompido = avaliacoes.some(e => e.interrompida)
  const nav = useNavigate()
  const { pathname } = useLocation()

  const [Principal, setPrincipal] = useState<iAvaliado>()

  function getStatus(modelo: iAvaliado): iStatus {
    let texto = ''
    let cor = ''
    let data = ''
    if (ConverterDataHora(Principal?.dataInicio ?? '01/01/0001 00:00').getTime() > new Date().getTime()) {
      texto = 'Aguardando início'
      cor = 'var(--Gray3)'
      data = `Início: ${Principal?.dataInicio ?? ''}`
    } else if (Principal?.encerrada && !PropInvalida(Principal?.pdiAtrasado) && Principal?.pdiAtrasado && !Principal?.pdi) {
      texto = 'PDI em atraso'
      cor = 'var(--Red)'
      data = `Até: ${ConverterDataHora(Principal?.dataFim ?? '01/01/0001 00:00', diasPdi).toLocaleDateString('pt-BR')}`
    } else if (Principal?.encerrada && !PropInvalida(Principal?.pdiAtrasado) && !(Principal?.pdiAtrasado && !Principal?.pdi)) {
      texto = 'Aguardando PDI'
      cor = 'var(--Purple1)'
      data = `Até: ${ConverterDataHora(Principal?.dataFim ?? '01/01/0001 00:00', diasPdi).toLocaleDateString('pt-BR')}`
    } else if (
      !PropInvalida(Principal?.autoavaliacao) && !PropInvalida(Principal?.gestor) &&
            (!Principal?.gestor || !Principal?.autoavaliacao) &&
            ConverterDataHora(Principal?.dataFim ?? '01/01/0001 00:00').getTime() < new Date().getTime()) {
      texto = 'Pesquisa em atraso'
      cor = 'var(--Orange)'
      data = `Fim ${Principal?.dataFim}`
    } else if (!Principal?.encerrada && !Principal?.interrompida) {
      texto = 'Pesquisa em andamento'
      cor = 'var(--Azul)'
      data = `Fim ${Principal?.dataFim}`
    }

    return {
      cor: cor,
      data: data,
      texto: texto
    }
  }

  function ObterChildren(): any {
    if (Principal === undefined) return

    const obj = getStatus(Principal)
    if (obj.cor === '' && obj.data === '' && obj.texto === '') return

    return (
        <>
            <Flex alignItems={'center'} gap='.5rem' minW={'11.5rem'} width={'11.5rem'}>
                <FaClipboardList color={obj.cor} size={28} />
                <Flex flexDir={'column'}>
                    <Text color={obj.cor} fontWeight={'700'} fontSize={'.813rem'}>{obj.texto}</Text>
                    <Text color={obj.cor} fontSize={'0.813rem'}>{obj.data}</Text>
                </Flex>
            </Flex>
            <Divider orientation='vertical' borderColor={'var(--Gray4)'} height={'2.5rem'} />
        </>
    )
  }

  useEffect(() => {
    setPrincipal(avaliacoes.filter(e =>
      (!avaliacoes.some(e => !e.encerrada && e.iniciada) && !PropInvalida(e.pdi) && !e.pdi && (e?.pdiAtrasado || !e?.pdiAtrasado)) ||
            !e.encerrada
    )[0])
  }, [avaliacoes])

  useEffect(() => {
    if (Principal) {
      const obj = getStatus(Principal)
      if (obj.cor !== '') {
        setIsDisabled(false)
      } else {
        setIsDisabled(true)
      }
    } else {
      setIsDisabled(false)
    }
  }, [Principal])

  if (IsDisabled) {
    return null
  }
  return (
        <Accordion allowToggle >
            <AccordionItem>
                {({ isExpanded }) => (
                    <>
                        <h2>
                            <Divider borderColor={'var(--Azul)'} />
                            <AccordionButton color={'black'} bg={isExpanded ? 'var(--AzulOpaco3)' : 'var(--AzulOpacoBg)'} _hover={{ backgroundColor: 'var(--AzulOpaco3)' }}>
                                <Box gap={'2rem'} alignItems={'center'} width={'100%'} display={'flex'} textAlign='left'>
                                    <Flex alignItems={'center'} gap={'1rem'} flex={'1'}>
                                        <Avatar w={'2.325rem'} h={'2.325rem'} src={avaliacoes[0]?.colaborador.avatar} name={avaliacoes[0]?.colaborador.nome} />
                                        <Flex flexDirection={'column'}>
                                            <span style={{ fontSize: '14px', fontWeight: 700, lineHeight: '16px' }}>{avaliacoes[0]?.colaborador.nome}</span>
                                            <span style={{ fontSize: '12px', lineHeight: '14px' }}>Área: {avaliacoes[0]?.colaborador.nomeDepartamento}</span>
                                            {avaliacoes[0]?.colaborador.gestorNome && <span style={{ fontSize: '12px', lineHeight: '14px' }}>Gestor: {avaliacoes[0]?.colaborador.gestorNome}</span>}
                                        </Flex>
                                    </Flex>

                                    {!interrompido &&
                                        <Flex gap='1rem' alignItems={'center'} flex={'1'}>
                                            <Flex alignItems={'center'}>
                                                <Img width={'2rem'} height={'2rem'} src={imgAvaliadores} />
                                                <ColumnText>
                                                    <h3 style={{ lineHeight: '90%' }}>{Principal?.totalAvaliadores}</h3>
                                                    <Text fontSize={'.75rem'}>Avaliadores</Text>
                                                </ColumnText>
                                            </Flex>

                                            <Divider orientation='vertical' borderColor={'var(--Gray4)'} height={'2.5rem'} />
                                            {ObterChildren()}

                                            {(Principal?.autoavaliacao !== null || Principal.gestor !== null) && (
                                                <Flex justifyContent={'center'} alignItems={'start'} flexDir={'column'}>
                                                    {
                                                        (Principal?.autoavaliacao !== null) && (
                                                            <Flex marginBottom={'.3rem'} alignItems='center'>
                                                                {(Principal?.autoavaliacao) && <FaCheckCircle color='var(--Green2)' style={{ marginRight: '.3rem' }} />}
                                                                {!Principal?.autoavaliacao && <FaRegTimesCircle color='var(--terc3)' style={{ marginRight: '.3rem' }} />}
                                                                <small>Autoavaliação</small>
                                                            </Flex>
                                                        )
                                                    }
                                                    {
                                                        (Principal?.gestor !== null) && (
                                                            <Flex marginBottom={'.3rem'} alignItems='center'>
                                                                {Principal?.gestor && <FaCheckCircle color='var(--Green2)' style={{ marginRight: '.3rem' }} />}
                                                                {!Principal?.gestor && <FaRegTimesCircle color='var(--terc3)' style={{ marginRight: '.3rem' }} />}
                                                                <small>Gestor</small>
                                                            </Flex>
                                                        )
                                                    }
                                                </Flex>
                                            )}
                                        </Flex>
                                    }

                                    <Flex alignItems={'center'} justifyContent={'end'} gap={'1rem'} flex={'1'} w='13.813rem'>
                                        {!interrompido &&
                                            <Flex gap={'.5rem'}>
                                                {
                                                    (((!Principal?.encerrada && !PropInvalida(Principal?.dataInicio) && Principal?.iniciada) ?? (!Principal?.encerrada && PropInvalida(Principal?.dataInicio))) && parseJwt().adm === 'True') && (
                                                        <ButtonCmp
                                                            small
                                                            leftIcon={<FaRegStopCircle />}
                                                            VarColor='Red'
                                                            onClick={() => onEncerrar(Principal?.id ?? '')}
                                                        >Encerrar</ButtonCmp>
                                                    )
                                                }

                                                {
                                                    ((!PropInvalida(Principal?.dataInicio) && !Principal?.encerrada)) && parseJwt().adm === 'True' && (
                                                        <ButtonCmp
                                                            small
                                                            rightIcon={<FaCog />}
                                                            VarColor='Green2'
                                                            onClick={() => {
                                                              if (openConfiguracao !== undefined) {
                                                                openConfiguracao(Principal ?? {} as iAvaliado)
                                                              }
                                                            }}
                                                        >Configurações</ButtonCmp>
                                                    )
                                                }

                                                {
                                                    (Principal?.encerrada && parseJwt().adm === 'True' && !interrompido) && (
                                                        <ButtonCmp
                                                            small
                                                            leftIcon={<FaRegPlayCircle />}
                                                            VarColor='Rosa'
                                                            onClick={() => {
                                                              if (!pesquisaEncerrada) {
                                                                onReabrir(Principal?.id ?? '')
                                                              } else {
                                                                onOpenModalReabrir(Principal?.id ?? '')
                                                              }
                                                            }}
                                                        >Reabrir</ButtonCmp>
                                                    )
                                                }

                                                {
                                                    Principal?.encerrada &&
                                                    (((parseJwt().cid === Principal?.colaborador.id && Principal?.liberarResultado === 0) ||
                                                        parseJwt().cid === Principal?.colaborador.gestorId) ||
                                                        parseJwt().role === '2' || parseJwt().adm === 'True') &&
                                                    (
                                                        <ButtonCmp
                                                            small
                                                            VarColor='Green2'
                                                            leftIcon={<FaChartBar />}
                                                            onClick={() => nav(`${SubstringRoute(pathname, 'ResultadoPesquisa')}ResultadoIndividual/${Principal?.id}`)}
                                                            disabled={Principal?.totalRespostas === 0 || (Principal?.autoavaliacao && Principal?.totalRespostas === 1)}
                                                        >Resultados</ButtonCmp>
                                                    )
                                                }
                                            </Flex>
                                        }
                                        <Flex alignItems={'center'} gap={'.25rem'}>
                                            {avaliacoes.some(e => e.interrompida) && <AiOutlineStop size='24' color='red' />}
                                            <Text fontSize={'12px'}>{isExpanded ? 'Minimizar' : 'Maximizar'}</Text>
                                            <AccordionIcon />
                                        </Flex>
                                    </Flex>
                                </Box>
                            </AccordionButton>
                            <Divider borderColor={'var(--Azul)'} />
                        </h2>
                        <Flex flexDir={'column'}>
                          {
                              avaliacoes.map((c, i2) => {
                                return (
                                      <AccordionPanel key={i2} px={'.5rem'} pt={'.75rem'} pb={'0'}>
                                          <CardAvaliado
                                              onEncerrar={onEncerrar}
                                              onReabrir={onReabrir}
                                              openConfiguracao={openConfiguracao}
                                              onOpenModalReabrir={onOpenModalReabrir}
                                              interromperPesquisa={avaliacoes.some(e => e.interrompida)}
                                              key={i2}
                                              avaliado={c}
                                              avaliacaoAnterior={avaliacoes[i2 - 1]}
                                              proximaAvaliacao={avaliacoes[i2 + 1]}
                                          />
                                      </AccordionPanel>
                                )
                              })
                          }
                        </Flex>
                        <AccordionPanel pb={4}>
                            {
                                !interrompido && parseJwt().adm === 'True' &&
                                (
                                    <ButtonCmp
                                        leftIcon={<FaRegStopCircle />}
                                        VarColor="Red"
                                        onClick={() => onOpenModalInterromper(Principal?.id ?? '')}
                                        size="sm"
                                        mr="2"
                                    >
                                        Interromper pesquisa
                                    </ButtonCmp>
                                )
                            }
                        </AccordionPanel>
                    </>
                )}
            </AccordionItem>
        </Accordion>
  )
}
