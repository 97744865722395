/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { Flex, Grid, Text, useToast } from '@chakra-ui/react'
import { ModalBody } from './styles'
import { useState } from 'react'
import { appApi, urlApi } from '../../../../../services/appApi'
import { ModalBase } from '../../../../../components/ModalBase'
import { ButtonCmp } from '../../../../../components/ButtonCmp'
import { PropInvalida } from '../../../../../Utils/Helper'
import { getToken } from '../../../../../services/token'

interface iModal {
  isOpen: boolean
  onRequestClose: () => void
  reload: () => void
  id: string
}

interface iErros {
  nome: string
  email: string
  cpf: string
  cargo: string
  celular: string
  departamento: string
  emailGestor: string
  cpfGestor: string
  nivelUsuario: string
  dataAdimissao: string
  enviarEmail: boolean
  squad: string
  papelSquad: string
  message?: string
}

export const ModalSubirEmMassa: React.FC<iModal> = ({ onRequestClose, isOpen, reload, id }) => {
  const toast = useToast()

  const [Arquivo, setArquivo] = useState<FormData>()
  const [Nome, setNome] = useState('')
  const [Erros, setErros] = useState<iErros[]>([])
  const [IsLoading, setIsLoading] = useState(false)

  const AtualizarArquivo = (arquivo: any): void => {
    const formdata = new FormData()
    formdata.append('arquivo', arquivo.target.files[0])
    setArquivo(formdata)
    setNome(arquivo.target.files[0].name)
    toast({
      title: 'Arquivo selecionado com sucesso!',
      status: 'success',
      isClosable: false,
      position: 'top-right',
      duration: 4000
    })
  }

  function EnviarEmMassa(): void {
    setIsLoading(true)
    appApi.post(`Indicador/${id}/uploadcsv`, Arquivo)
      .then(res => {
        toast({
          title: 'Dados enviados com sucesso!',
          status: 'success',
          isClosable: false,
          position: 'top-right',
          duration: 4000
        })

        if (res.data.length > 0) {
          setErros(res.data)
        } else {
          onRequestClose()
        }
        reload()
        setIsLoading(false)
      })
      .catch(err => {
        console.log(err)
        setIsLoading(false)
      })
  }

  return (
    <ModalBase
      Titulo='Subir dados em massa'
      isOpen={isOpen}
      onClose={onRequestClose}
      Width='65rem'
    >
      <ModalBody>
        <span><strong>Instruções para gerar o arquivo no Excel:</strong> Baixe o arquivo de exemplo e atualize apenas as colunas "real" e "previsto", <b>não modifique os textos das colunas "ano" e "mês".</b> Caso não queira atualizar algum ano ou mês, basta excluir a linha e retirar os espaçamentos. A estrutura já está formatada, com o mês e o ano indicados. Após a atualização, salve o arquivo como <b>CSV UTF-8 (Delimitado por vírgulas)</b>.
        </span>

        <label htmlFor='Ifile'>{Arquivo ? Nome : 'Selecione um arquivo'}</label>
        <input onChange={(e) => AtualizarArquivo(e)} id='Ifile' type={'file'} />
        {Erros?.length > 0 &&
          <Flex flexDir={'column'} width={'100%'} border={'1px solid var(--Red)'} borderRadius='1rem' mt={'1rem'} as={'ul'} padding='1rem 1rem 1rem 2rem'>
          <Grid templateColumns={'repeat(3, 1fr)'} alignItems={'center'} mb={'1rem'}>
            <div></div>
            <Text textAlign={'center'} fontWeight='bold'>Erros na planilha</Text>
          </Grid>
          <Flex flexWrap={'wrap'} gap={'2rem'}>
            {Erros?.map((e, i) => {
              return (
                <li key={i} style={{ whiteSpace: 'nowrap', width: '100%' }}>{e.message}</li>
              )
            })}
          </Flex>
        </Flex>}
      </ModalBody>
      <Flex justifyContent={'end'} padding='1rem' gap={'.5rem'}>
        <ButtonCmp
          onClick={() => { window.location.href = `${urlApi}/Indicador/${id}/Csv?token=${getToken()}` }}
          VarColor='Rosa'
        >Baixar exemplo para preenchimento</ButtonCmp>

        <ButtonCmp
          isDisabled={PropInvalida(Arquivo)}
          onClick={EnviarEmMassa}
          VarColor='Green2'
          isLoading={IsLoading}
        >Enviar arquivo</ButtonCmp>
      </Flex>
    </ModalBase>
  )
}
